<template>
    <div class="site-wrapper no-header">
        <div class="container">
            <div class="buttons hideForPrint">
                <span v-if="showBack" @click="goBack()" class="button is-info"
                    ><span><font-awesome-icon icon="chevron-left"/></span> &nbsp; Back</span
                >
            </div>
            <!-- {{ publicProfileQuals }} -->

            <div class="profile-wrapper">
                <div v-if="loadingData" class="profile-main">
                    <span><font-awesome-icon icon="cog" class="fa-spin" />&nbsp;Loading user profile settings...</span>
                    <div class="spacer s40"></div>
                </div>

                <div v-else class="profile-main">
                    <div v-if="dataError">
                        <h3 class="title">{{ errorMessage }}</h3>

                        <p>
                            Refresh the page and if the issue remains, please either give us a call on
                            <a href="tel:+61-447-139-382">+61 447 139 382</a>, or email <a href="mailto:help@offshoresms.com.au?subject=Question from Offshore SMS">help@offshoresms.com.au</a>.
                        </p>
                    </div>

                    <div v-else>
                        <div class="profile-header">
                            <div class="profile-background">
                                <h4 class="user-name"><font-awesome-icon icon="user" />&nbsp; {{ codeData.username }}</h4>
                                <!-- <p class="italic">User ID: {{ codeData.userid }}</p> -->
                                <p class="italic">Location: {{ userData.homeport }}</p>
                                <div class="profile-avatar">
                                    <span v-if="userData.avatarUrl != ''" :style="{ backgroundImage: `url(${userData.avatarUrl})` }" class="avatar"></span>
                                    <span v-else class="avatar round-button-letters">{{ getAvatar(userData.username) }}</span>
                                </div>
                            </div>
                        </div>

                        <div v-if="sectionsEmpty">
                            <p class="bold italic red">No profile fields have been activated.</p>
                        </div>

                        <section v-if="visibleSections.includes('basic')" class="profile-section">
                            <div class="section-heading">
                                <div class="section-title" :class="{ hide: !visibleSections.includes('basic') }">
                                    Profile Information
                                </div>
                                <div class="section-visibility">
                                    <span v-if="visibleSections.includes('basic')"><font-awesome-icon icon="eye"/></span>
                                    <span v-else class="hide"><font-awesome-icon icon="eye-slash"/></span>
                                </div>
                            </div>
                            <div v-if="visibleSections.includes('basic')" class="section-content">
                                <div v-if="userData.personalBio && userData.personalBio != ''">
                                    <div v-for="(field, ix) in userData.profileCustomFields" :key="`field-${ix}`">
                                        <div class="profile-field">
                                            <div class="pf-label">
                                                {{ field.label }}
                                            </div>
                                            <div class="pf-details">
                                                <div v-for="(line, ix) in field.details.split('\n')" :key="`line1-${ix}`">{{ line }}<br /></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-else>
                                    <p>No profile info added yet.</p>
                                </div>
                            </div>
                            <div v-else class="section-content hide">
                                <p class="italic">Private</p>
                            </div>
                        </section>

                        <section v-if="visibleSections.includes('bio')" class="profile-section">
                            <div class="section-heading">
                                <div class="section-title" :class="{ hide: !visibleSections.includes('bio') }">
                                    Personal Bio
                                </div>
                                <div class="section-visibility">
                                    <span v-if="visibleSections.includes('bio')"><font-awesome-icon icon="eye"/></span>
                                    <span v-else class="hide"><font-awesome-icon icon="eye-slash"/></span>
                                </div>
                            </div>
                            <div v-if="visibleSections.includes('bio')" class="section-content">
                                <div class="bio-details">
                                    <div v-if="userData.personalBio && userData.personalBio != ''">
                                        <div v-for="(line, ix) in userData.personalBio.split('\n')" :key="`line2-${ix}`">{{ line }}<br /></div>
                                    </div>
                                    <div v-else>
                                        <p>No bio created yet.</p>
                                    </div>
                                </div>
                            </div>
                            <div v-else class="section-content hide">
                                <p class="italic">Private</p>
                            </div>
                        </section>

                        <!-- <section class="profile-section">
                            <div class="section-heading">
                                <div
                                    class="section-title"
                                    :class="{ hide: !visibleSections.includes('contacts') }"
                                >
                                    Emergency Contacts
                                </div>
                                <div class="section-visibility">
                                    <span v-if="visibleSections.includes('contacts')"
                                        ><font-awesome-icon icon="eye"
                                    /></span>
                                    <span v-else class="hide"
                                        ><font-awesome-icon icon="eye-slash"
                                    /></span>
                                </div>
                            </div>
                            <div
                                v-if="visibleSections.includes('contacts')"
                                class="section-content"
                            >
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Quod
                                mollitia, vitae tenetur consequuntur numquam optio autem debitis
                                voluptas fuga laborum error, quae corporis eum distinctio, inventore
                                facere saepe natus enim.
                            </div>
                            <div v-else class="section-content hide">
                                <p class="italic">Private</p>
                            </div>
                        </section> -->

                        <section v-if="visibleSections.includes('skills')" class="profile-section">
                            <div class="section-heading">
                                <div class="section-title" :class="{ hide: !visibleSections.includes('skills') }">
                                    Skills
                                </div>
                                <div class="section-visibility">
                                    <span v-if="visibleSections.includes('skills')"><font-awesome-icon icon="eye"/></span>
                                    <span v-else class="hide"><font-awesome-icon icon="eye-slash"/></span>
                                </div>
                            </div>
                            <div v-if="visibleSections.includes('skills')" class="section-content">
                                <div class="tag-wrapper">
                                    <div class="tag is-medium" v-for="(item, idx) in mySkills" :class="{ noted: item.note != '' }" :key="`skill-${idx}`" @click="setTagNote(item, item.note != '')">
                                        {{ item.title }}&nbsp;
                                        <span v-if="item.note != ''"><font-awesome-icon icon="info-circle"/></span>
                                    </div>
                                </div>
                            </div>
                            <div v-else class="section-content hide">
                                <p class="italic">Private</p>
                            </div>
                        </section>

                        <section v-if="visibleSections.includes('quals')" class="profile-section">
                            <div class="section-heading">
                                <div class="section-title" :class="{ hide: !visibleSections.includes('quals') }">
                                    Qualifications
                                </div>
                                <div class="section-visibility">
                                    <span v-if="visibleSections.includes('quals')"><font-awesome-icon icon="eye"/></span>
                                    <span v-else class="hide"><font-awesome-icon icon="eye-slash"/></span>
                                </div>
                            </div>
                            <div v-if="visibleSections.includes('quals')" class="section-content">
                                <div class="tag-wrapper">
                                    <div
                                        class="tag is-medium"
                                        v-for="(item, inx) in mySavedQuals"
                                        :class="{
                                            noted: item.qualificationPhoto && item.qualificationPhoto != ''
                                        }"
                                        :key="`myqual-${inx}`"
                                        @click="viewSavedQual(item)"
                                    >
                                        <span v-if="item.isExpired"><font-awesome-icon icon="exclamation-triangle"/></span>&nbsp; {{ item.title }}&nbsp;
                                        <span v-if="item.qualificationPhoto && item.qualificationPhoto != ''"><font-awesome-icon icon="info-circle"/></span>
                                        &nbsp;
                                        <span
                                            class="blue
                                                item.qualificationPhoto &&
                                                    item.qualificationPhoto != ''
                                            "
                                            ><font-awesome-icon icon="camera"
                                        /></span>
                                    </div>
                                    <div class="tag is-medium" v-for="(item, idx) in myQuals" :class="{ noted: item.note != '' }" :key="`qual-${idx}`" @click="setTagNote(item, item.note != '')">
                                        <span v-if="item.isExpired"><font-awesome-icon icon="exclamation-triangle"/></span>&nbsp; {{ item.title }}&nbsp;
                                        <span v-if="item.note != ''"><font-awesome-icon icon="info-circle"/></span>
                                    </div>
                                </div>
                            </div>
                            <div v-else class="section-content hide">
                                <p class="italic">Private</p>
                            </div>
                        </section>

                        <section v-if="visibleSections.includes('institutions')" class="profile-section">
                            <div class="section-heading">
                                <div class="section-title" :class="{ hide: !visibleSections.includes('institutions') }">
                                    Training Institutions
                                </div>
                                <div class="section-visibility">
                                    <span v-if="visibleSections.includes('institutions')"><font-awesome-icon icon="eye"/></span>
                                    <span v-else class="hide"><font-awesome-icon icon="eye-slash"/></span>
                                </div>
                            </div>
                            <div v-if="visibleSections.includes('institutions')" class="section-content">
                                <div class="tag-wrapper">
                                    <div
                                        class="tag is-medium"
                                        v-for="(item, idx) in myInstitutions"
                                        :class="{ noted: item.note != '' }"
                                        :key="`insto-${idx}`"
                                        @click="setTagNote(item, item.note != '')"
                                    >
                                        {{ item.title }}&nbsp;
                                        <span v-if="item.note != ''"><font-awesome-icon icon="info-circle"/></span>
                                    </div>
                                </div>
                            </div>
                            <div v-else class="section-content hide">
                                <p class="italic">Private</p>
                            </div>
                        </section>

                        <section v-if="visibleSections.includes('experience')" class="profile-section">
                            <div class="section-heading">
                                <div class="section-title" :class="{ hide: !visibleSections.includes('experience') }">
                                    Work History
                                </div>
                                <div class="section-visibility">
                                    <span v-if="visibleSections.includes('experience')"><font-awesome-icon icon="eye"/></span>
                                    <span v-else class="hide"><font-awesome-icon icon="eye-slash"/></span>
                                </div>
                            </div>
                            <div v-if="visibleSections.includes('experience')" class="section-content">
                                <div class="tag-wrapper">
                                    <div class="tag job is-medium noted" v-for="(item, idx) in myExperience" :key="`job-${idx}`" @click="showJob(item)">
                                        <span class="green" v-if="item.current"><font-awesome-icon icon="star"/></span>
                                        &nbsp; {{ item.workplace }}&nbsp;
                                        <span><font-awesome-icon icon="info-circle"/></span>
                                    </div>
                                </div>
                            </div>
                            <div v-else class="section-content hide">
                                <p class="italic">Private</p>
                            </div>
                        </section>

                        <!-- <div class="buttons">
                            <span id="pdfButton" class="button is-primary" @click="savePDF()">{{
                                buttonText
                            }}</span>
                        </div> -->
                    </div>
                </div>

                <div class="sidebar">
                    <div class="right-side">
                        <img src="@/assets/img/sidebar-ad-1.jpg" />
                    </div>

                    <hr />
                    <div class="right-side">
                        <img src="@/assets/img/sidebar-blue-ad-3.jpg" />
                    </div>

                    <hr />

                    <!-- <h4>Profile page features</h4>

                    <ul class="list">
                        <li>Photo</li>
                        <li>Color theme</li>
                        <li>Current availability status</li>
                        <li>Work Experience (list)</li>
                        <li>Location</li>
                        <li>Socials</li>
                        <li>Search for other people</li>
                        <li>Option to login / manage profile</li>
                    </ul> -->
                </div>
            </div>

            <div class="profile-footer">
                <p>
                    OffshoreSMS &copy; 2023
                </p>
            </div>

            <div class="copy-prompt hideForPrint" :class="{ visible: showCopyPrompt }">
                Copied to clipboard!
            </div>

            <simple-modal :title="selectedItem.title" theme="is-primary" @closed="showTagNote = false" v-if="showTagNote">
                <p>{{ selectedItem.note }}</p>
            </simple-modal>

            <simple-modal :title="selectedJob.workplace" theme="is-primary" @closed="showTagJob = false" v-if="showTagJob">
                <div class="work-field">
                    <div v-if="selectedJob.current">
                        <span class="green"><font-awesome-icon icon="star" /> &nbsp; Current Job</span>
                        <div class="spacer s10"></div>
                    </div>
                    <div class="work-label">Location</div>
                    <div class="work-details">{{ selectedJob.location }}</div>
                    <div class="work-label">Job Role</div>
                    <div class="work-details">{{ selectedJob.role }}</div>
                    <div class="work-label">Notes</div>
                    <div class="work-details" v-for="(line, ix) in selectedJob.notes.split('\n')" :key="`line3-${ix}`">{{ line }}<br /></div>
                    <div class="work-label">Tasks</div>
                    <div class="work-details">
                        <div class="work-tasks" v-for="(task, idx) in selectedJob.tasks" :key="`task-${idx}`">
                            <div v-for="(line, ix) in task.split('\n')" :key="`line3-${ix}`">
                                {{ line }}
                            </div>
                        </div>
                    </div>
                    <!-- <div class="work-label">Last Updated</div>
                    <div class="work-details">{{ selectedJob.updatedAt }}</div> -->
                </div>
            </simple-modal>

            <div class="modal modal-fx-fadeInScale" v-bind:class="{ 'is-active': showQualPhoto }">
                <div class="modal-background" @click="close"></div>
                <div class="modal-card">
                    <header class="modal-card-head">
                        <p class="modal-card-title">{{ selectedQual.title }}</p>
                        <button class="delete" aria-label="close" @click="close"></button>
                    </header>
                    <section class="modal-card-body">
                        <div>Expiry Date: {{ selectedQual.humanDate }}</div>
                        <div class="spacer s20"></div>
                        <div v-if="selectedQual.qualificationPhoto != ''" class="content">
                            <iframe width="100%" height="300px" :src="selectedQual.qualificationPhoto" frameborder="0"></iframe>
                        </div>
                        <div v-else>Photo could not be loaded.</div>
                    </section>
                    <footer class="modal-card-foot">
                        <button class="button cancel" @click="close">Close</button>
                    </footer>
                </div>
            </div>
        </div>

        <div class="spacer s50"></div>
    </div>
</template>

<script>
import { db } from '../main.js';
import NProgress from 'nprogress';

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/functions';
const SimpleModal = () => import('../reusables/SimpleModal.vue');

export default {
    name: 'FastTrackViewSms',

    props: {
        sentUserID: String,
        sentUserName: String,
        showBack: Boolean
    },

    components: {
        SimpleModal
    },

    computed: {
        displaySettings() {
            return this.userData.publicProfileData && this.userData.publicProfileData.displaySettings ? this.userData.publicProfileData.displaySettings : {};
        },
        visibleSections() {
            // return Object.entries(this.displaySettings).map((key) => this.auditList[key]);
            return Object.entries(this.displaySettings)
                .filter(m => m[1] == true)
                .map(n => n[0]);
        },
        sectionsEmpty() {
            return this.visibleSections.length == 0;
        }
    },

    mounted: function() {
        let self = this;

        this.finalPublicCode = this.$route.query.x ? this.$route.query.x : '';
        this.finalToken = this.$route.query.token ? this.$route.query.token : '';

        if (this.finalPublicCode != '') {
            this.loadPublicCodeData();
        } else {
            setTimeout(() => {
                self.dataError = true;
                self.errorMessage = 'No user found.';
                self.loadingData = false;
            }, 1000);
        }
        // this.today = new Date().toDateString();
    },

    data: function() {
        return {
            finalUserID: '',
            finalPublicCode: '',
            finalToken: '',
            loadingData: true,
            codeData: {},
            userData: {},
            userName: '',

            buttonText: 'Download PDF',

            // today: '',

            htmlToPdfOptions: {
                margin: 0.3,

                filename: `OffshoreSMS-User-Profile.pdf`,

                image: {
                    type: 'jpeg',
                    quality: 2
                },

                enableLinks: false,

                html2canvas: {
                    scale: 1,
                    useCORS: true
                },

                jsPDF: {
                    unit: 'in',
                    format: 'a4',
                    orientation: 'portrait'
                }
            },
            hideForPrint: false,
            showCopyPrompt: false,

            dataError: false,
            errorMessage: '',

            showTagNote: false,
            selectedItem: {},
            mySkills: [],
            myQuals: [],
            mySavedQuals: [],
            myInstitutions: [],
            myExperience: [],
            showTagJob: false,
            selectedJob: {},
            publicProfileQuals: [],
            showQualPhoto: false,
            selectedQual: {},
            loadingImage: false
        };
    },

    methods: {
        loadPublicCodeData() {
            let self = this;
            db.collection('profileLinkIDs')
                .doc(self.finalPublicCode)
                .get()
                .then(code => {
                    if (code.exists) {
                        console.log('loaded code data');
                        self.codeData = code.data();
                        self.userName = code.data().username;
                        self.finalUserID = code.data().userid;
                        self.dataError = false;
                        self.loadUserData();
                    } else {
                        setTimeout(() => {
                            self.loadingData = false;
                            self.errorMessage = 'There was an error finding this user profile, sorry.';
                            self.dataError = true;
                        }, 1000);
                    }
                })
                // .then(() => {
                //     setTimeout(() => {
                //         self.loadingData = false;
                //         self.errorMessage = '';
                //     }, 1000);
                // })
                .catch(err => {
                    setTimeout(() => {
                        self.loadingData = false;
                        self.dataError = true;
                        self.errorMessage = "There was an error finding this user's profile, sorry.";
                    }, 1000);
                });
        },
        loadUserData() {
            let self = this;

            db.collection('userdata')
                .doc(self.finalUserID)
                .onSnapshot(doc => {
                    if (doc.exists) {
                        console.log('loaded user data', doc.data());
                        let publicProfileData = doc.data().publicProfileData ? doc.data().publicProfileData : {};
                        let allowPublicProfile = publicProfileData.allowPublicProfile ? publicProfileData.allowPublicProfile : false;
                        let publicLink = publicProfileData.publicLink ? publicProfileData.publicLink : '';
                        self.publicProfileQuals = doc.data().publicProfileQuals ? doc.data().publicProfileQuals : [];
                        if (allowPublicProfile && publicLink == self.finalPublicCode) {
                            self.userData = doc.data();
                            self.userName = doc.data().username;
                            setTimeout(() => {
                                self.loadSkills();
                                self.loadMySavedQuals();
                                self.loadQuals();
                                self.loadInstitutions();
                                self.loadMyExperience();
                                self.dataError = false;
                                self.errorMessage = '';
                                self.loadingData = false;
                            }, 1000);
                        } else {
                            setTimeout(() => {
                                self.loadingData = false;
                                self.errorMessage = !allowPublicProfile ? 'This user profile is private, sorry.' : 'Invalid link';
                                self.dataError = true;
                            }, 1000);
                        }
                    } else {
                        setTimeout(() => {
                            self.loadingData = false;
                            self.errorMessage = 'We could not find this user profile, sorry.';
                            self.dataError = true;
                        }, 1000);
                    }
                });
            // .then(() => {
            //     setTimeout(() => {
            //         self.loadingData = false;
            //         self.errorMessage = '';
            //     }, 1500);
            // })
            // .catch(err => {
            //     setTimeout(() => {
            //         self.loadingData = false;
            //         self.dataError = true;
            //         self.errorMessage =
            //             'There was an error while loading this user profile, sorry.';
            //     }, 1500);
            // });
        },

        close() {
            this.showTagNote = false;
            this.selectedItem = {};
            this.showTagJob = false;
            this.selectedJob = {};
            this.showQualPhoto = false;
            this.selectedQual = {};
            this.loadingImage = false;
        },

        setTagNote(item, active) {
            if (!active) return;
            this.selectedItem = item;
            this.showTagNote = true;
        },

        viewSavedQual(qual) {
            console.log(qual);
            let self = this;
            if (qual.qualificationPhoto != '') {
                this.selectedQual = qual;
                this.showQualPhoto = true;
                this.loadingImage = true;
                setTimeout(() => {
                    self.loadingImage = false;
                }, 1500);
            }
        },

        showJob(job) {
            console.log(job);
            this.selectedJob = job;
            this.showTagJob = true;
        },

        loadSkills() {
            let self = this;
            db.collection('userdata')
                .doc(self.finalUserID)
                .collection('myProfileSkills')
                .orderBy('title', 'asc')
                .onSnapshot(querySnapshot => {
                    self.mySkills = [];
                    querySnapshot.forEach(doc => {
                        let data = doc.data();
                        data.id = doc.id;
                        self.mySkills.push(data);
                    });
                });
        },

        loadMySavedQuals() {
            let self = this;
            db.collection('userdata')
                .doc(self.finalUserID)
                .collection('myQualifications')
                .orderBy('expiryDate', 'asc')
                .onSnapshot(querySnapshot => {
                    self.mySavedQuals = [];
                    querySnapshot.forEach(doc => {
                        let data = doc.data();
                        data.id = doc.id;
                        let expiryDate = doc.data().expiryDate ? doc.data().expiryDate.toDate() : '';
                        let isPrivate = doc.data().isPrivate ? doc.data().isPrivate : false;
                        let now = new Date();
                        data.isExpired = expiryDate == '' ? false : expiryDate.getTime() < now.getTime();
                        if (!isPrivate && self.publicProfileQuals.includes(doc.id)) {
                            self.mySavedQuals.push(data);
                        }
                    });
                });
        },

        loadQuals() {
            let self = this;
            db.collection('userdata')
                .doc(self.finalUserID)
                .collection('myProfileQuals')
                .orderBy('title', 'asc')
                .onSnapshot(querySnapshot => {
                    self.myQuals = [];
                    querySnapshot.forEach(doc => {
                        let data = doc.data();
                        data.id = doc.id;
                        let expiryDate = doc.data().expiryDate ? doc.data().expiryDate.toDate() : '';
                        let now = new Date();
                        data.isExpired = expiryDate == '' ? false : expiryDate.getTime() < now.getTime();
                        self.myQuals.push(data);
                    });
                });
        },

        loadInstitutions() {
            let self = this;
            db.collection('userdata')
                .doc(self.finalUserID)
                .collection('myProfileInstitutions')
                .orderBy('title', 'asc')
                .onSnapshot(querySnapshot => {
                    self.myInstitutions = [];
                    querySnapshot.forEach(doc => {
                        let data = doc.data();
                        data.id = doc.id;
                        self.myInstitutions.push(data);
                    });
                });
        },

        loadMyExperience() {
            let self = this;
            db.collection('userdata')
                .doc(self.finalUserID)
                .collection('myProfileExperience')
                .orderBy('workplace', 'asc')
                .onSnapshot(querySnapshot => {
                    self.myExperience = [];
                    querySnapshot.forEach(doc => {
                        let data = doc.data();
                        data.id = doc.id;
                        self.myExperience.push(data);
                    });
                });
        },

        copyText(isArray, isDate, data) {
            console.log(isArray, isDate, data);
            let self = this;
            let dateString = isDate ? data.toDate().toLocaleString('en-US', { timeZone: 'Australia/Sydney' }) : '';
            let copyData = isArray ? data.join('\r\n') : isDate ? dateString : data;
            this.$copyText(copyData).then(
                function(e) {
                    console.log(copyData);
                    self.showCopied();
                },
                function(e) {
                    alert('This data cannot be copied');
                    console.log(e);
                }
            );
        },

        getAvatar(crewName) {
            // console.log(crewName);
            let parts = crewName.length > 0 ? crewName.split(' ') : [crewName];
            let initials = '';
            for (let i = 0; i < parts.length; i++) {
                if (parts[i].length > 0 && parts[i] !== '') {
                    initials += parts[i][0];
                }
            }
            return initials;
        },

        showCopied() {
            this.showCopyPrompt = true;
            setTimeout(() => {
                this.showCopyPrompt = false;
            }, 1000);
        },

        formatDate: function(sentDate, format) {
            // return moment(sentDate).format(format);
            let fixDate = sentDate ? sentDate.toDate() : new Date();
            return fixDate;
        },

        showDate(d) {
            const dateOptions = {
                timeZone: 'Australia/Sydney',
                month: 'short',
                day: 'numeric',
                year: 'numeric',
                hour: 'numeric',
                minute: 'numeric'
            };
            const t = new Date(d.toDate());
            return t.toLocaleDateString('en-US', dateOptions); // "June 1, 2019"
        },

        sorted: function(arr) {
            // Set slice() to avoid to generate an infinite loop!
            return arr.slice().sort(function(a, b) {
                return a.index - b.index;
            });
        },

        goBack: function() {
            this.$router.go(-1);
        },

        getTextAnswer(answer) {
            return Array.isArray(answer) ? answer : [answer];
        },

        simpleDate(sentDate) {
            console.log(sentDate);
            let fixDate = sentDate ? sentDate.toDate() : new Date();
            const dateOptions = {
                timeZone: 'Australia/Sydney',
                month: 'short',
                day: 'numeric',
                year: 'numeric',
                hour: 'numeric',
                minute: 'numeric'
            };
            const myDate = new Date(sentDate.toDate());
            return myDate.toLocaleDateString('en-US', dateOptions); // "June 1, 2019"
        },

        savePDF: function() {
            this.buttonText = 'Preparing PDF. Please wait...';
            var self = this;

            var generalEmail = firebase.functions().httpsCallable('generalEmail');
            generalEmail({
                senderEmail: '',
                senderName: '',
                senderUserID: '',
                subject: 'Custom log response report printed: ' + self.userData.customChecklistName,
                message: 'Someone has printed a custom log response report for vessel ' + self.userName + ' (ID: ' + self.finalUserID + ')'
            });

            // First we take the HTML of the page
            var html = document.documentElement.outerHTML;

            // Now we prepare the data that we pass to the API
            // In this example we want to hide the section with the example so we pass custom CSS
            // Note that you don't need to pass any other parameters if your defaults in the members area are already good
            var css = '.header, .copy-link { display:none !important;} #pdfButton {display:none;} .vue-back-to-top {display: none;} .hideForPrint {display: none;} .narrow {display: none;}';
            var data = { html: html, license: 'E197E92RdrKd', css: css };
            var serialized = Object.keys(data)
                .map(function(k) {
                    return encodeURIComponent(k) + '=' + encodeURIComponent(data[k]);
                })
                .join('&');

            //console.log(serialized);

            // You can insert an "in progress" message here

            // We now prepare the API call
            var xhttp = new XMLHttpRequest();
            xhttp.onreadystatechange = function() {
                var a;
                if (xhttp.readyState === 4 && xhttp.status === 200) {
                    // The PDF is now generated
                    // You can remove the "in progress" message here

                    // Now we show the PDF to the user
                    a = document.createElement('a');
                    a.href = window.URL.createObjectURL(xhttp.response);
                    a.download = self.userData.customChecklistName + '.pdf';
                    a.style.display = 'none';
                    document.body.appendChild(a);
                    a.click();
                    //console.log("One");
                    self.buttonText = 'Download PDF';
                }
            };

            // This is the actual call to our API
            xhttp.open('POST', 'https://pdfmyurl.com/api', true);
            xhttp.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
            xhttp.responseType = 'blob';
            xhttp.send(serialized);
            //console.log("Two");

            //NProgress.done();
        }
    }
};
</script>
<style lang="scss" scoped>
#app .header {
    position: relative !important;
}

.site-wrapper .container {
    max-width: 1040px;
}

.profile-wrapper {
    display: flex;
    justify-content: space-around;
    .profile-main {
        padding-right: 4rem;
        flex-grow: 1;
    }
    .sidebar {
        flex-basis: 230px;
        flex-grow: 0;
        flex-shrink: 0;
        // border: 1px solid #e4e4e4;
        // padding: 1rem;
        // border-radius: 5px;
        margin-top: 20px;
        ul {
            list-style: inside disc;
        }
        img {
            width: 100%;
            max-width: 350px;
            margin: 0 auto;
            display: block;
        }
    }
}

.profile-header {
    margin-top: 20px;
    .profile-background {
        background-color: blueviolet;
        background-image: url('https://source.unsplash.com/oQl0eVYd_n8');
        background-size: cover;
        background-position: center;
        margin-bottom: 100px;
        position: relative;
        height: 200px;
        border-radius: 5px;
        padding: 1.5rem;
        color: #fff;
        .profile-avatar {
            position: absolute;
            left: 30px;
            bottom: -60px;
            span {
                background: deepskyblue;
                &.avatar {
                    width: 120px;
                    height: 120px;
                    display: block;
                    background-size: cover;
                    background-position: center;
                    border-radius: 50%;
                    border: 4px solid #fff;
                    &.round-button-letters {
                        background: steelblue;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-size: 3rem;
                        font-weight: bold;
                    }
                }
            }
        }
    }
}

.profile-section {
    margin-bottom: 3rem;
    background: #fdfdfd;
    border: 1px solid #e4e4e4;
    border-radius: 5px;
    .section-heading {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #e4e4e4;
        padding: 10px 12px;
        .section-title {
            font-weight: bold;
            color: dodgerblue;
            &.hide {
                color: #666;
            }
        }
        .section-visibility {
            display: none;
            span {
                color: mediumseagreen;
                &.hide {
                    color: #aaa;
                }
            }
        }
    }

    .section-content {
        font-size: 0.95rem;
        padding: 10px 12px;
        .tag-wrapper {
            display: flex;
            flex-wrap: wrap;
            .tag {
                margin-right: 7px;
                margin-bottom: 7px;
                transition: 0.25s;
                cursor: default;
                font-size: 0.9rem;
                &.noted {
                    cursor: pointer;
                    span {
                        color: orange;
                        &.green {
                            color: mediumseagreen;
                        }
                        &.blue {
                            color: cadetblue;
                        }
                    }
                    &:hover {
                        background: skyblue;
                        color: #fff;
                        span {
                            color: #fff;
                        }
                    }
                }
                &.job {
                }
            }
        }
        .profile-field {
            margin-bottom: 1.3rem;
            .pf-label {
                font-weight: bold;
                font-size: 0.9rem;
                margin-bottom: 4px;
                color: darkorange;
            }
            .pf-details {
                margin-left: 10px;
            }
        }
    }
}

.work-field {
    .work-label {
        font-weight: bold;
        font-size: 0.9rem;
        margin-bottom: 4px;
        color: darkorange;
    }
    .work-details {
        margin-left: 10px;
        margin-bottom: 1rem;
        .work-tasks {
        }
    }
}

.edit-note {
    margin-top: 1rem;
    font-size: 1rem;
    color: steelblue;
    font-style: italic;
}

.copy-prompt {
    position: fixed;
    top: 80px;
    right: 20px;
    background: #a4dba4;
    color: #fff;
    padding: 1rem;
    border-radius: 6px;
    opacity: 0;
    transition: 0.2s;
    z-index: 9999;
    &.visible {
        opacity: 1;
    }
}
.content {
    // min-height: 400px;
}

.profile-footer {
    width: 100%;
    margin-top: 6rem;
    border-top: 1px solid #e4e4e4;
    padding-top: 3rem;
    margin-bottom: 5rem;
}

hr {
    // margin-bottom: 4rem !important;

    &.narrow {
        margin-bottom: 1.5rem !important;
    }
}

h3 {
    font-size: 1.2rem !important;
    text-transform: uppercase !important;
    margin-top: 2.5rem !important;
}
h4 {
    &.user-name {
        text-transform: none;
        margin-bottom: 0.8rem;
        font-weight: bold;
        font-size: 28px;
        font-family: 'Roboto', sans-serif;
    }
}

table {
    margin-top: 1rem !important;
    table-layout: fixed !important;
    width: 100% !important;

    tr {
        th {
            font-weight: normal !important;
            border: 1px solid #d4d4d4;
            padding: 12px 20px;
            font-family: 'Open Sans', sans-serif;
            text-align: left !important;
            word-break: break-word;
            position: relative;
            .copy-link {
                position: absolute;
                top: 0;
                right: 0;
                z-index: 99;
                width: 40px;
                height: 40px;
                background: #f4f4f4;
                // display: flex;
                align-items: center;
                justify-content: center;
                display: none;
                cursor: pointer;
            }
            &:hover {
                .copy-link {
                    display: flex;
                }
            }
        }

        td {
            width: 50% !important;
            border: 1px solid #d4d4d4 !important;
            padding: 8px 12px;
            font-family: 'Open Sans', sans-serif;
            text-align: left !important;
            word-break: break-word;
            .link {
                margin-top: 5px;
            }
        }
    }
}

.pagebreak {
    display: block !important;
    clear: both !important;
    page-break-after: always !important;
}

@media only screen and (max-width: 900px) {
    .profile-wrapper {
        flex-direction: column;
        .profile-main {
            padding-right: 0;
        }
        .sidebar {
            width: 100%;
            flex-basis: auto;
        }
    }
}
</style>
